.login-container1{
    margin: 7rem;
}

.login-container2{
    display: flex;
    flex-direction: row;
    border-radius: '16px';
    shape-outside: margin-box;
    border: 1px solid rgba(228, 225, 211, 12);
}

.login-left-content{
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 7rem;
    width:50%
}

.login-left-logo-text{
    text-align: center;
}

.login-right-content{
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 7rem;
    width:50%
}

.login-right-heading{
    text-align: center;
    margin-top: 2rem;
}

.login-right-form{
    width: 200px;
}

.login-right-form:nth-child(1){
    margin: 4rem;
}

.login-right-form:nth-child(2){
    margin: 4rem;
}

.login-right-buttons{
    justify-content: center;
   display: flex;
   flex-direction: row;
   margin: 1rem;
}

.login-right-loginbutton{

   display: flex;
   flex-direction: row;
   width: 200px;
}

.login-right-fptext{
    text-align: center;
    margin: 1rem;
}

.login-rignt-registertext{
    text-align: center;
    margin-bottom: 1rem;
}