.admin-dashoard-container{
    display: flex;
    flex-direction: row;
    margin-left: 160px;
}

.admin-dashboard-cards{
    margin-left: 260px;
    display: flex;
    flex-direction: row;
}

.admin-dashboard-card{
    width: fit-content;
    margin: 10px;
}

.admin-dashboard-left-side{
    width: 30%;
    margin: 100px;
    margin-top: 7px;
}

.admin-dashboard-right-side{
    width: 30%;
    margin: 100px;
    margin-top: 7px;
}

.ad-ls-parta{
    width: 20rem;
    margin: 4rem;
}

.ad-ls-partb{
    width: 34rem;
    margin: 4rem;
    margin-top: 7rem;
}

.ad-rs-parta{
    width: 20rem;
    margin: 4rem;
}

.ad-rs-partb{
    width: 34rem;
    margin: 4rem;
    margin-top: 7rem;
}