.AdTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    }
    
    .AdTable th {
    background-color: #e6e6e6;
    font-weight: bold;
    text-align: left;
    padding: 10px;
    }
    
    .AdTable td {
    border: 1px solid #cccccc;
    padding: 10px;
    }
    
    .AdTable td a {
    color: #0066cc;
    text-decoration: none;
    }
    
    .AdTable td a:hover {
    text-decoration: underline;
    }
    
    .AdTable td button {
    border: none;
    background-color: transparent;
    color: #0066cc;
    cursor: pointer;
    padding: 0;
    margin: 0;
    }
    
    .AdTable td button:hover {
    text-decoration: underline;
    }
    
    .AdTable .btn-warning {
    background-color: #ffc107;
    color: #212529;
    }
    
    .AdTable .btn-warning:hover {
    background-color: #ffcd38;
    }
    
    .AdTable .btn-black {
    background-color: #212529;
    color: #ffffff;
    }
    
    .AdTable .btn-black:hover {
    background-color: #343a40;
    }