#app {
  height: 100vh;
  min-height: 100vh;
  background-color: #f0f0f0;
  text-align: center;
}

.main {
  float: center;
  margin-left: 200px;
  width: 70%;
  height: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
}
.main-center {
  text-align: center;
}
.sub-main {
  font-size: 20px;
  box-shadow: 11px 12px 13px 12px rgb(207, 207, 207);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 20px;
  background-color: white;
}
.main-profile {
  text-align: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  padding-top: 70px;
  padding-bottom: 70px;
}
.sub-main-profile {
  justify-content: center;

  font-size: 20px;
  box-shadow: 5px 5px 1px 2px rgb(207, 207, 207);
  padding: 50px;
  margin-bottom: 10px;
  border-radius: 20px;
  border-style: solid;
  border-color: rgb(202, 202, 202);
  background-color: white;
}

.forgot-button {
  float: center;
  width: 200px;
  height: 40px;
  margin: 20px;
  border-radius: 5px;
  background-color: #a0a0a0;
  color: rgb(0, 0, 0);
  font-size: 20px;
  border: none;
}
.forgot-button:hover {
  background-color: #bebebe;
}
.forgot-button:active {
  background-color: #666666;
}

.logout-button {
  font-size: 2rem;
  height: 60px;
  background-color: black;
  color: white;
  border: none;
}
.logout-button:hover {
  color: #bebebe;
}
.logout-button:active {
  color: #a0a0a0;
}

.table {
  text-align: center;
  border-style: solid;
}
td {
  width: 400px;
}

.account-sub-main {
  justify-content: center;
  width: auto;
  height: auto;
  font-size: 20px;
  /* box-shadow: 5px 5px 1px 2px rgb(207, 207, 207); */
  padding: 50px;
  margin-bottom: 10px;
  border-radius: 20px;
  border-style: solid;
  border-color: rgb(202, 202, 202);
  background-color: white;
}
.account-button {
  margin: 0px 0px 0px 5px;
  padding: 10px;
  min-width: 100px;
  width: auto;
  height: auto;
  border-radius: 20px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  border: none;
}
.account-button-blue {
  margin: 0px 0px 0px 5px;
  padding: 10px;
  min-width: 100px;
  width: auto;
  height: auto;
  border-radius: 20px;
  background-color: rgb(81, 164, 237);
  color: rgb(0, 0, 0);
  font-size: 20px;
  border: none;
}
.account-button-blue-add {
  margin: 0px 20px 0px 20px;
  min-width: 100px;
  width: auto;
  height: 50px;
  border-radius: 20px;
  background-color: rgb(81, 164, 237);
  color: rgb(0, 0, 0);
  font-size: 20px;
  border: none;
}
.list {
  margin: 50px;
}
.list-sub-table {
  justify-content: center;
  padding: 10px;
  height: auto;
  border-radius: 20px;
  color: rgb(0, 0, 0);
  box-shadow: 11px 12px 13px 12px rgb(207, 207, 207);
  background-color: white;
  font-size: 25px;
  border: none;
}
.head {
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 10px;
}
.head-left {
  text-align: center;
  justify-content: left;
  display: inline-flex;
  padding-top: 10px;
}
.head-right {
  text-align: center;
  float: right;
  display: inline-flex;
  padding-top: 10px;
}

.search-input {
  text-align: center;
  width: 400px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50px;
  box-shadow: inset 0px 0px 5px 0px rgb(202, 197, 197);
  border: none;
  outline: none;
}

.profilePic {
  height: 300px;
  width: 300px;
}

.form-radio {
  text-align: center;
  border-radius: 5px;
  box-shadow: inset 0px 0px 5px 0px rgb(202, 197, 197);
  border: none;
  outline: none;
  padding: 10px;
  margin: 10px;
}
.form-radio-space {
  margin-top: 7px;
}

/* .report-table {
  display: none;
} */
