.addad-contatiner {
    height: 170vh;
    margin-left: 250px;
  }
  
  .addad-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: 700px;
    margin: auto;
}
  
  .addad-form-left {
    
  }
  
  .addad-form-right {
    
  }
  


  
  .addad-form-submit {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .addad-form-submit:hover {
    background-color: #0069d9;
  }

  .custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
  }
  
  .custom-file-upload:hover {
    background-color: #0069d9;
  }
  
  .custom-file-upload:active {
    background-color: #0053ba;
  }
  
  