.sidebar {
  height: 100vh;
  width: 20%;
  border-radius: 0px 40px 40px 0px;
  min-width: 250px;
  position: fixed;
  background-color: #2d2c33;
}
.brand {
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 20px;
}

.brand .title1 {
  font-family: "Kanit", sans-serif;
  font-weight: bold;
  font-size: 60px;
}



.links {
  height: 60%;
  width: 100%;
  margin-right: 100px;
}

.sidebar-list {
  height: auto;
  width: 100%;
}

.sidebar-list .sidebar-item {
  width: 100%;
  height: 70px;
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.sidebar-list .sidebar-item:hover {
  cursor: pointer;
  color: #ffa6006e;
}

.sidebar-list #selected {
  color: #ffa500;
}

.sidebar-item #icon {
  flex: 30%;
  display: grid;
  place-items: center;
}
.sidebar-item #title {
  flex: 70%;
}
