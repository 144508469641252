.register-container1{
    margin: 7rem;
}

.register-container2{
    display: flex;
    flex-direction: row;
    border-radius: '16px';
    shape-outside: margin-box;
    border: 1px solid rgba(228, 225, 211, 12);
    -webkit-border-radius: '16px';
    -moz-border-radius: '16px';
    -ms-border-radius: '16px';
    -o-border-radius: '16px';
}

.register-left-content{
    justify-content: center;
    display: flex;
    flex-direction: column;
    width:40%
}

.register-left-logo-text{
    text-align: center;
}

.register-right-content{
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 4rem;
    width:60%
}

.register-right-heading{
    text-align: center;
    margin-top: 2rem;
}

.register-right-form{
    width: 200px;
}

.register-right-form:nth-child(1){
    margin: 4rem;
    display: flex;
    flex-direction: row;
}

.register-right-form:nth-child(2){
    margin: 4rem;
    display: flex;
    flex-direction: row;
}

.register-right-buttons{
    justify-content: center;
   display: flex;
   flex-direction: row;
   margin-bottom: 4rem;
}

.register-right-form-parta{
    margin: 10px;
}

.register-right-form-partb{
    margin: 10px;
}

.register-right-loginbutton{

   display: flex;
   flex-direction: row;
   width: 200px;
}

.register-right-fptext{
    text-align: center;
    margin: 1rem;
}

.register-rignt-registertext{
    text-align: center;
    margin-bottom: 1rem;
}